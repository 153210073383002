// src/hooks/properties/useGetProperties.js
import { useApiQuery } from "@/hooks/useApiQuery";
import { getProperties } from "@/services/properties/getProperties";

export const useGetProperties = ({ accessToken, auth0UserId }) => {
	return useApiQuery({
		queryKey: ["properties", auth0UserId],
		queryFn: () => getProperties({ accessToken, auth0UserId }),
		accessToken,
		auth0UserId,
		enabled: !!accessToken && !!auth0UserId,
	});
};
