// src/components/buttons/BlueLink.js
import Link from "next/link";

const BlueLink = ({ link, children, isFile = false }) => {
	return (
		<Link
			href={link}
			{...(isFile && {
				target: "_blank",
				rel: "noopener noreferrer",
			})}
		>
			<span className="text-blue-600 hover:text-blue-800 cursor-pointer">{children}</span>
		</Link>
	);
};

export default BlueLink;
