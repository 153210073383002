// src/hooks/useGetOrganizations.js
import { useApiQuery } from "@/hooks/useApiQuery";
import { getOrganizations } from "@/services/organizations/getOrganizations";

export const useGetOrganizations = ({ accessToken, auth0UserId }) => {
	return useApiQuery({
		queryKey: ["organizations"],
		queryFn: () => getOrganizations({ accessToken }),
		accessToken,
		auth0UserId,
		enabled: !!auth0UserId && !!accessToken,
	});
};
