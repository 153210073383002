// src/components/buttons/LinkButton.js
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Link from "next/link";

const LinkButton = ({ href, children }) => {
	return (
		<Link href={href} className="inline-block">
			<span className="btn bg-black hover:bg-gray-800 transition duration-300 text-white px-4 py-2 rounded font-semibold flex items-center group">
				{children}
				<ChevronRightIcon className="ml-2 h-5 w-5 transition-transform duration-300 transform group-hover:translate-x-1" />
			</span>
		</Link>
	);
};

export default LinkButton;
