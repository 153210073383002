// components/feedback/SuccessAlert.js
import React from "react";

const SuccessAlert = React.memo(() => {
	return (
		<div
			role="alert"
			aria-live="assertive"
			className="fixed z-50 top-20 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-green-100 border border-green-400 text-green-700 rounded-md shadow-sm animate-slide-down"
		>
			Successfully saved data
		</div>
	);
});

SuccessAlert.displayName = "SuccessAlert";

export default SuccessAlert;
