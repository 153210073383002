// src/components/data-display/OrganizationsContent.js
import LinkButton from "@/components/buttons/LinkButton";
import OrganizationTable from "@/components/data-display/OrganizationTable";
import ErrorMessage from "@/components/feedback/ErrorMessage";
import LoadingState from "@/components/feedback/LoadingState";
import SearchInput from "@/components/inputs/SearchInput";
import { useGetOrganizations } from "@/hooks/organizations/useGetOrganizations";
import { useAccessTokenQuery } from "@/hooks/useAccessTokenQuery";
import { useSearch } from "@/hooks/useSearch";
import { useUser } from "@auth0/nextjs-auth0/client";

const OrganizationsContent = () => {
	const { user } = useUser();
	const auth0UserId = user?.sub;
	const { data: accessToken, isError: isErrorAccessToken, isLoading: isLoadingAccessToken } = useAccessTokenQuery();
	const {
		data: organizations,
		isLoading: isLoadingOrganizations,
		isError: isErrorOrganizations,
	} = useGetOrganizations({ accessToken, auth0UserId });
	const {
		searchTerm,
		handleSearch,
		clearSearch,
		filteredData: filteredOrganizations,
	} = useSearch({
		data: organizations,
		searchProperty: "name",
	});

	if (isLoadingAccessToken || isLoadingOrganizations) {
		return <LoadingState />;
	}
	if (isErrorAccessToken || isErrorOrganizations) {
		return <ErrorMessage />;
	}

	return (
		<>
			<h2 className="text-xl font-semibold mb-2">Organizations Management</h2>
			<hr className="mb-2 sm:mb-3" />
			<div className="mb-4 flex flex-col space-y-4">
				<SearchInput
					searchTerm={searchTerm}
					onSearch={handleSearch}
					onClear={clearSearch}
					placeholder="Search by organization name"
					containerClassName="w-full"
				/>
				<div className="flex space-x-4">
					<LinkButton href="/admin/organizations/add">Add Organization</LinkButton>
				</div>
			</div>
			<OrganizationTable organizations={filteredOrganizations || []} />
		</>
	);
};

export default OrganizationsContent;
