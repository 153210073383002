// components/data-display/OrganizationTable.js
import BlueLink from "@/components/buttons/BlueLink";
import React from "react";

const OrganizationTable = React.memo(({ organizations }) => {
	return (
		<div className="overflow-x-auto">
			<table className="min-w-full table-auto">
				<thead>
					<tr>
						<th scope="col" className="text-left px-4 py-2">
							Name
						</th>
						<th scope="col" className="text-left px-4 py-2">
							Address
						</th>
						<th scope="col" className="text-left px-4 py-2">
							Phone Number
						</th>
						<th scope="col" className="text-left px-4 py-2">
							Email
						</th>
					</tr>
				</thead>
				<tbody>
					{organizations.map((org, index) => (
						<tr key={org.public_id} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
							<td className="px-4 py-2">
								<BlueLink link={`/admin/organizations/${org.organization_slug}`}>{org.name}</BlueLink>
							</td>
							<td className="px-4 py-2">{org.address}</td>
							<td className="px-4 py-2">{org.phone_number}</td>
							<td className="px-4 py-2">{org.email}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
});

OrganizationTable.displayName = "OrganizationTable";

export default OrganizationTable;
