// src/hooks/users/useGetUser.js
import { useApiQuery } from "@/hooks/useApiQuery";
import { getUser } from "@/services/users/getUser";

export const useGetUser = ({ auth0UserId, accessToken }) => {
	return useApiQuery({
		queryKey: ["user", auth0UserId],
		queryFn: () => getUser({ auth0UserId, accessToken }),
		accessToken,
		auth0UserId,
		enabled: !!auth0UserId && !!accessToken,
	});
};
